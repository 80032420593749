import { ApproverHome, Leaderboard,  } from "../routes";
import { Tabs, Tab} from '@material-ui/core';
import TabPanel from '../components/TabPanel';
import React from 'react';

export default function Approver() {
  const [tabIndex, setTabIndex] = React.useState(0);
  const onChange = (event, newValue) => {
    setTabIndex(newValue);
  }
  return (<>
    <Tabs value={tabIndex} onChange={onChange}>
      <Tab label="Approvals" />
      <Tab label="Leaderboard" />
    </Tabs>
    <TabPanel value={tabIndex} index={0} className="paragraph_i_guess">
      <ApproverHome />
    </TabPanel>
    <TabPanel value={tabIndex} index={1}>
      <Leaderboard />
    </TabPanel>
    </>
  );
}
