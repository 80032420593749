import React from "react";
import { DataStore } from "aws-amplify";
import { Event, EventStatus, Approval, ApprovalStatus } from "../models";
import {cacheContext} from "../contexts/cache";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputLabel  
} from "@material-ui/core";

export default function NewEvent({ isOpen, handleClose }) {

  const { husbandPointsUser, husbands, approvers } = React.useContext(cacheContext);
  const [formState, updateFormState] = React.useState({
    Husband: husbands? husbands[0] : '',
    headline: "",
    description: "",
    date: "",
    status: EventStatus.DRAFT,
    pointValue: 0,
    creator: husbandPointsUser,
    numberApproved: 0,
    numberDenied: 0,
    numberBlank: approvers ? approvers.length : 0
  });

  if(!isOpen){
    return <></>;
  }

  const formChangeHandler = async ({target}) => {
    const { type, name, value, checked } = target;
    if (type === "number") {
      await updateFormState(formState => ({...formState, [name]: parseInt(value, 10)}));
    } else {
      await updateFormState(formState => ({...formState, [name]: value || checked}));
    }
  };

  const canSubmit = 
    formState.Husband !== null && 
    formState.headline !== null && 
    formState.pointValue !== 0 && 
    formState.date !== '';
  
  const saveData = async (eventToSave) => {
    return await DataStore.save(new Event({...eventToSave, creator: husbandPointsUser, numberBlank: approvers.length}));
  };

  const handleSubmit = async () => {
    formState.status = EventStatus.PENDING;
    const event = await saveData(formState);
    const approvals = [];
    approvers?.forEach(async approver => {
      const approvalToSave = {
        Approver: approver,
        approved: ApprovalStatus.BLANK,
        timestamp: new Date().toISOString(),
        approverId: approver.id,
        Event: event,
        eventID: event.id
      };
      const approval = await DataStore.save(new Approval(approvalToSave));
      // approvals.push(approval);
    });
    await DataStore.save(Event.copyOf(event, event => {
      event.Approvals = approvals;
    }))
    handleClose();
  };
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-new-event"
      >
        <DialogTitle id="form-new-event">New Event</DialogTitle>
        <DialogContent>
          <InputLabel id="select-husband-label">Select Husband</InputLabel>
          <Select
            labelId="select-husband-label"
            id="select-husband"
            label="Select a Husband"
            onChange={formChangeHandler}
            onSelect={formChangeHandler}
            name="Husband"
            value = {formState.Husband}
            fullWidth
          >
            {husbands?.map(husband =>
              <MenuItem 
                value={husband} 
                key={husband.id}
                >
                  {husband.name}
                </MenuItem>)}
          </Select>
          <TextField
            autoFocus
            name="headline"
            label="Headline"
            fullWidth
            onChange={formChangeHandler}
          />
          <TextField
            name="description"
            label="Description"
            fullWidth
            multiline
            rows={3}
            onChange={formChangeHandler}
          />
          <TextField
            name="date"
            label="Date"
            fullWidth
            type="date"
            onBlur={formChangeHandler}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name="pointValue"
            label="Point Value"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="number"
            onChange={formChangeHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button 
            disabled={!canSubmit}
            onClick={handleSubmit} color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
