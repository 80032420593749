// import logo from "./logo.svg";
import Amplify from "aws-amplify";
import "./App.css";
import awsmobile from "./aws-exports";
import { Hub, Auth } from "aws-amplify";
import { DataStore } from '@aws-amplify/datastore';
import React from "react";
import LoggedOut from "./routers/LoggedOut";
import Approver from "./routers/Approver";
import Husband from "./routers/Husband";
import { getAllApprovers, getAllHusbands, getApprovals, getUserInfo, getEvents } from './queries';
import { cacheContext } from './contexts/cache';
import { Event } from './models';
import NewEvent from './components/NewEvent';

import {
  AppBar,
  Button,
  Fab,
  Toolbar,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

const LOG = {
  log: console.log,
  error: console.error,
  debug: console.debug,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 100
  },
}));

Amplify.configure(awsmobile);
function App() {
  const [cacheState, updateCacheState] = React.useState({});
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [addEventModal, setAddEventModal] = React.useState(false);

  React.useEffect(() => {
    const getCognitoUser = async () => {
      try{
        const currentUser = await Auth.currentAuthenticatedUser();
        if(currentUser){
          await setIsLoggedIn(true);
          const userId = currentUser.username;
          console.log(userId);
          const userInfo = await getUserInfo(userId);
          const husbands = await getAllHusbands();
          const approvers = await getAllApprovers();
          await updateCacheState(cacheState => ({...cacheState,  ...userInfo, husbands, approvers}) );
        }
      } catch(e){
        LOG.debug("NO SAVED SESSION");
      }
    }    
    try{
      getCognitoUser();
    } catch(e){
      LOG.debug('NO SAVED SESSION');
    }
  },[])

  React.useEffect(() => {
    LOG.debug('RE-RUNNING AUTH LISTENER');
    const authListener = async (data) => {
      const event = data?.payload?.event.toUpperCase();
      switch (event) {
        case "SIGNOUT":
        if (isLoggedIn) {
          LOG.debug("RUNNING SIGNOUT HANDLER");
          await updateCacheState({});
          await setIsLoggedIn(false);
        }
        break;
        case "SIGNIN":
        if (!isLoggedIn) {
          LOG.debug("RUNNING SIGNIN HANDLER");
          
          console.log(data.payload.data);
          const userId = data.payload.data.username;
          console.log('USERID',userId);
          const userInfo = await getUserInfo(userId);
          const husbands = await getAllHusbands();
          const approvers = await getAllApprovers();
          await updateCacheState(cacheState => ({...cacheState,  ...userInfo, husbands, approvers}));
          await setIsLoggedIn(true);
        }
        break;
        default:
          console.log(event);
      }
    };
    Hub.listen("auth", authListener);
    return () => {
      LOG.debug('REMOVING AUTH LISTENER')
      Hub.remove("auth", authListener);
    };
  }, [isLoggedIn]);

  const updateApprovals = React.useCallback(async () => {
    const approver = cacheState.approver;
    const approvals = await getApprovals({approver});
    updateCacheState(cacheState => ({...cacheState, approvals}));
  }, [cacheState.approver]);

  const updateEvents = React.useCallback(async () => {
    const events = await getEvents();
    updateCacheState(cacheState => ({...cacheState, events}));
  }, []);

  React.useEffect(() => {
    if(cacheState.isApprover){
      updateApprovals();
    } else if(cacheState.isHusband) {
      updateEvents();
    }
  }, [cacheState.isApprover, cacheState.isHusband, updateApprovals, updateEvents]);

  React.useEffect(() => {
    if(cacheState.isApprover){
      const eventSubscription = DataStore.observe(Event)
         .subscribe(updateApprovals)
      return () => {
        eventSubscription.unsubscribe();
      }
    }
  }, [cacheState.isApprover, updateApprovals])

  React.useEffect(() => {
    if(cacheState.isHusband){
      const eventSubscription = DataStore.observe(Event)
      .subscribe(updateEvents)
      return () => {
        eventSubscription.unsubscribe();
      }
    }
  }, [cacheState.isHusband, updateEvents])


  const classes = useStyles();

          // <IconButton
          //   edge="start"
          //   className={classes.menuButton}
          //   color="inherit"
          //   aria-label="menu"
          // >
          //   <MenuIcon />
          // </IconButton>
          return (
            <>
            <AppBar position="sticky">
            <Toolbar>
            <Typography variant="h6" className={classes.title}>
            HusbandPoints
            </Typography>
            {isLoggedIn ? (
              <Button color="inherit" onClick={() => Auth.signOut()}>
              Logout
              </Button>
              ) : (
              <></>
              )}
              </Toolbar>
              </AppBar>
              {cacheState == null ? (
                <>Loading</>
                ) : (
                <cacheContext.Provider value={cacheState}>
                <NewEvent isOpen={addEventModal} handleClose={() => setAddEventModal(false)} />
                {isLoggedIn ? (
                  <>
                  <Fab
                  size="medium"
                  color="primary"
                  aria-label="add"
                  className={classes.fab}
                  onClick={() => setAddEventModal(true)}
                  >
                  <AddIcon />
                  </Fab>
                  <>{cacheState.isApprover ? <Approver /> : <Husband />}</>
                  </>
                  ) : (
                  <LoggedOut />
                  )}
                  </cacheContext.Provider>
                  )}
                </>
                );
        }

        export default App;
