import { DataStore, SortDirection } from '@aws-amplify/datastore';
import { Approval, ApprovalStatus, EventStatus } from '../models';

export default async function getApprovals({approver}){
	if(approver){
		const approverId = approver.id;
		const pendingApprovals = await DataStore.query(Approval,
			approver => approver
				.approverId('eq',approverId)
				.approved('eq',ApprovalStatus.BLANK),
			{
				sort: approver => approver.createdAt(SortDirection.DESCENDING)
			});
		const completedApprovals = await DataStore.query(Approval, 
			approver => approver
				.approved('ne', ApprovalStatus.BLANK)
				.approverId('eq', approverId),{
			sort: approver => approver
				.createdAt(SortDirection.DESCENDING),
			limit: 20,
			page: 0
		});
		console.log(pendingApprovals);
		console.log(completedApprovals);

		const approvalsToDo = pendingApprovals
			.filter(approval => approval.Event != null)
			.filter(approval => approval.Event.status === EventStatus.PENDING)
		const approvalsDone = [
			...completedApprovals, 
			...pendingApprovals
				.filter(approval => approval.Event != null)
				.filter(approval =>approval.Event.status !== EventStatus.PENDING )
		].sort((approvalA, approvalB) => approvalB.createdAt - approvalA.createdAt);
		const result = [...approvalsToDo, ...approvalsDone];
		return result;
	}
	return [];
}
