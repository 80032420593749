import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@material-ui/core";
import React from "react";
import { cacheContext } from "../contexts/cache";
import { Approval, ApprovalStatus, EventStatus, Event, Husband } from "../models";
import { withStyles } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";
import { DataStore } from "@aws-amplify/datastore";

export default function ApproverHome() {
  const cache = React.useContext(cacheContext); 
  
  if (cache.approvals == null) {
    return <>loading</>;
  }
  console.log(cache.approvals);
  return <ApprovalList approvals={[...cache.approvals]} />;
}

function ApprovalList({ approvals }) {
  if (!approvals) {
    return <>no events?</>;
  }
  // return <></>;
  return approvals
    .filter(approval => approval.Event != null)
    .map((approval) => 
      <EventListItem 
        myApproval={approval} 
        event={approval.Event} 
        key={approval.id} 
      />);
}

function EventListItem({ event, myApproval }) {
  const cache = React.useContext(cacheContext);
  const husband = cache.husbands?.find(husband => husband.id === event.eventHusbandId);
  const styles = {
    headline: {
      fontWeight: 'bolder'
    },
    pointTotal: {
      float: "right",
    },
    description:{
      clear: 'both',
      float: 'left'
    },
    creator:{
      fontStyle: 'italic',
      clear: 'both',
      float: 'left',
      fontSize: '0.75rem'
    },
    nameBox: {
      float: 'left',
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      alignItems: 'center'
    },
    husbandName: {
      marginLeft: '5px'
    }
  };

  const RedButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[400]),
      backgroundColor: red[400],
      "&:hover": {
        backgroundColor: event.status === EventStatus.PENDING ? red[600] : red[400]
      },
      "&.MuiButton-outlined":{
        color: 'black',
        borderColor: red[400],
        backgroundColor: 'white'
      },
      "&.Mui-disabled, &.MuiButton-outlined.Mui-disabled":{
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        border: '0'
      }
    }
  }))(Button);
  const GreenButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(green[300]),
      backgroundColor: green[300],
      "&:hover": {
        backgroundColor: event.status === EventStatus.PENDING ? green[500] : green[300]
      },
      "&.MuiButton-outlined":{
        color: 'black',
        borderColor: green[300],
        backgroundColor: 'white'
      },
      "&.Mui-disabled, &.MuiButton-outlined.Mui-disabled":{
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        border: '0'
      }
    }
  }))(Button);

  async function approve() {
    if (myApproval.approved !== ApprovalStatus.APPROVED && event.status === EventStatus.PENDING) {
      const wasBlank = myApproval.approved === ApprovalStatus.BLANK;
      await DataStore.save(
        Approval.copyOf(myApproval, updated => {
          updated.approved = ApprovalStatus.APPROVED
        })
      );
      const updatedEvent = await DataStore.save(
        Event.copyOf(event, updated => {
          updated.numberApproved = event.numberApproved + 1;
          updated.numberDenied = event.numberDenied - (wasBlank ? 0 : 1);
          updated.numberBlank = event.numberBlank - (wasBlank ? 1 : 0);
          if(updated.numberApproved > updated.numberDenied + updated.numberBlank){
            updated.status = EventStatus.APPROVED;
          }
        })
      );
      if (updatedEvent.status === EventStatus.APPROVED){
        await DataStore.save(
          Husband.copyOf(husband, updated =>{
              updated.pointTotal += event.pointValue;
          })
        )
      }
    } 
  }

  async function deny() {
    if (myApproval.approved !== ApprovalStatus.REJECTED && event.status === EventStatus.PENDING) {
       const wasBlank = myApproval.approved === ApprovalStatus.BLANK;
       await DataStore.save(
        Approval.copyOf(myApproval, updated => {
          updated.approved = ApprovalStatus.REJECTED
        })
      );
       await DataStore.save(
        Event.copyOf(event, updated => {
          updated.numberApproved = event.numberApproved - (wasBlank ? 0 : 1);
          updated.numberDenied = event.numberDenied + 1;
          updated.numberBlank = event.numberBlank - (wasBlank ? 1 : 0);
          if(updated.numberDenied >= updated.numberApproved + updated.numberBlank){
            updated.status = EventStatus.DENIED;
          }
        })
      );
     
    }
  }

  function Actions() {
    return (
      <CardActions style={{float: 'right'}}>
        <RedButton
          variant={
            myApproval.approved === ApprovalStatus.REJECTED
              ? "outlined"
              : "contained"
          }
          size="small"
          disabled={event.status === EventStatus.APPROVED}
          onClick={() => deny()}
          disableElevation={event.status !== EventStatus.PENDING}
        >
          {event.status === EventStatus.DENIED ? "Denied" : "Deny"}{" "}
          {`(${event.numberDenied})`}
        </RedButton>
        <GreenButton
          variant={
            myApproval.approved === ApprovalStatus.APPROVED
              ? "outlined"
              : "contained"
          }
          size='small'
          disabled={event.status === EventStatus.DENIED}
          onClick={() => approve()}
          disableElevation={event.status !== EventStatus.PENDING}
        >
          {event.status === EventStatus.APPROVED ? "Approved" : "Approve"}{" "}
          {`(${event.numberApproved})`}
        </GreenButton>
      </CardActions>
    );
  }

  return (
    <Card className="Umm" elevation={5} style={{margin: '10px'}}>
      <CardContent>
        <div style={styles.nameBox}>
          <Typography variant="h5" style={styles.headline}>
            {event.headline}
          </Typography>
          <Typography variant="subtitle2" style={styles.husbandName}>
            ({husband.name})
          </Typography>
        </div>
        <Typography variant="h5" style={styles.pointTotal}>
          {event.pointValue}
        </Typography>
      </CardContent>
      <CardContent >
        <Typography variant="body1" style={styles.description} align='left'>
          {event.description}
        </Typography>
      </CardContent>      
      <Actions />
      <CardContent>
        <Typography variant="body1" style={styles.creator}>Created on {event.date}</Typography>
      </CardContent>
    </Card>
  );
}
