import React from "react";
import { cacheContext } from "../contexts/cache";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@material-ui/core";

export default function Leaderboard() {
  const cache = React.useContext(cacheContext);
  return (
    <>
      {cache.husbands.map((husband) => (
        <LeaderboardEntry husband={husband} key={husband.id} />
      ))}
    </>
  );
}

function LeaderboardEntry({ husband }) {
  const cardStyle = {
    margin: "10px",
  };
  const cardActionsStyle = {
    float: "right",
    clear: "right",
  };
  return (
    <Card elevation={3} style={cardStyle}>
      <CardContent>
        <Typography style={{ float: "left" }} variant="h5">
          {husband.name}
        </Typography>
        <Typography
          style={{ float: "right" }}
          variant="h6"
          color="textSecondary"
        >
          {husband.totalPoints}
        </Typography>
      </CardContent>
      <CardActions style={cardActionsStyle}>
        {/*<Button variant="">Events</Button>
        <Button variant="contained" color="primary">
          Add Event
        </Button>*/}
      </CardActions>
    </Card>
  );
}
