import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@material-ui/core";
import React from "react";
import { cacheContext } from "../contexts/cache";
import { EventStatus } from "../models";
import { withStyles } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";

export default function HusbandHome() {
  const cache = React.useContext(cacheContext); 
  
  if (cache.events == null) {
    return <>loading</>;
  }
  return <EventList events={cache.events} />;
}

function EventList({ events }) {
  if (!events) {
    return <>no events?</>;
  }
  // return <></>;
  return events
    .map((event) => 
      <EventListItem 
        event={event} 
        key={event.id} 
      />);
}

function EventListItem({ event }) {
  const styles = {
    headline: {
      fontWeight: 'bolder'
    },
    pointTotal: {
      float: "right",
    },
    description:{
      clear: 'both',
      float: 'left'
    },
    creator:{
      fontStyle: 'italic',
      clear: 'both',
      float: 'left',
      fontSize: '0.75rem'
    },
    nameBox: {
      float: 'left',
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      alignItems: 'center'
    },
    husbandName: {
      marginLeft: '5px'
    }
  };

  const RedButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[400]),
      backgroundColor: red[400],
      "&:hover": {
        backgroundColor: event.status === EventStatus.PENDING ? red[600] : red[400]
      },
      "&.MuiButton-outlined":{
        color: 'black',
        borderColor: red[400],
        backgroundColor: 'white'
      },
      "&.Mui-disabled, &.MuiButton-outlined.Mui-disabled":{
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        border: '0'
      }
    }
  }))(Button);
  const GreenButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(green[300]),
      backgroundColor: green[300],
      "&:hover": {
        backgroundColor: event.status === EventStatus.PENDING ? green[500] : green[300]
      },
      "&.MuiButton-outlined":{
        color: 'black',
        borderColor: green[300],
        backgroundColor: 'white'
      },
      "&.Mui-disabled, &.MuiButton-outlined.Mui-disabled":{
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        border: '0'
      }
    }
  }))(Button);


  function Actions() {
    return (
      <CardActions style={{float: 'right'}}>
        <RedButton
          variant="outlined"
          size="small"
          disabled={event.status === EventStatus.APPROVED}
          disableElevation={event.status !== EventStatus.PENDING}
        >
          {event.status === EventStatus.DENIED ? "Denied" : "Denials"}{" "}
          {`(${event.numberDenied})`}
        </RedButton>
        <GreenButton
          variant="outlined"
          size='small'
          disabled={event.status === EventStatus.DENIED}
          disableElevation={event.status !== EventStatus.PENDING}
        >
          {event.status === EventStatus.APPROVED ? "Approved" : "Approvals"}{" "}
          {`(${event.numberApproved})`}
        </GreenButton>
      </CardActions>
    );
  }

  return (
    <Card elevation={5} style={{margin: '10px'}}>
      <CardContent>
        <div style={styles.nameBox}>
          <Typography variant="h5" style={styles.headline}>
            {event.headline}
          </Typography>
          <Typography variant="subtitle2" style={styles.husbandName}>
            ({event.Husband.name})
          </Typography>
        </div>
        <Typography variant="h5" style={styles.pointTotal}>
          {event.pointValue}
        </Typography>
      </CardContent>
      <CardContent >
        <Typography variant="body1" style={styles.description} align='left'>
          {event.description}
        </Typography>
      </CardContent>      
      <Actions />
      <CardContent>
        <Typography variant="body1" style={styles.creator}>Created on {event.date}</Typography>
      </CardContent>
    </Card>
  );
}
