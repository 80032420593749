import React from "react";
import { Auth } from "aws-amplify";

import { TextField, Button } from "@material-ui/core";

export default function Login() {
  const [loginFormState, setLoginFormState] = React.useState({
    username: "",
    password: "",
  });
  const [errorState, setErrorState] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const formChangeHandler = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setLoginFormState({ ...loginFormState, [name]: value });
  };

  const loginAttempt = async () => {
    const { username, password } = loginFormState;
    console.log(loginFormState);
    try {
      await Auth.signIn(username, password);
    } catch (e) {
      console.error(e);
      setErrorState(true);
      setHelperText("Email and Password don't match");
    }
  };

  const formContainerStyle = {
    maxWidth: "600px",
    padding: "100px",
    margin: "0 auto",
    border: "10px solid grey",
  };
  return (
    <div style={formContainerStyle}>
      <TextField
        autoFocus
        name="username"
        label="Email"
        error={errorState}
        helperText={helperText}
        fullWidth
        onChange={formChangeHandler}
      />
      <TextField
        name="password"
        label="Password"
        type="password"
        error={errorState}
        helperText={helperText}
        fullWidth
        onChange={formChangeHandler}
      />
      <Button onClick={loginAttempt}>Log in</Button>
    </div>
  );
}
