import { DataStore } from '@aws-amplify/datastore';
import { HusbandPointsUser } from '../models';

export default async function getUserInfo(userId){
	const userCandidates = await DataStore
		.query(HusbandPointsUser, user => user.userId('eq',userId));
	const husbandPointsUser = userCandidates[0];
	
	if(!husbandPointsUser){
		console.error('User not found');
		return {};
	} else{
		const isHusband = husbandPointsUser.isHusband;
		const husband = isHusband ? husbandPointsUser.Husband : null;

		const isApprover = !isHusband;
		const approver = isApprover ? husbandPointsUser.Approver : null;

		const userInfo = {husbandPointsUser, isHusband, husband, isApprover, approver};
		return userInfo;	
	}
}