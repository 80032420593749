import { Auth } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import React from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Button, Typography } from "@material-ui/core";
import { HusbandPointsUser } from '../models';


export default function Join(){
  const { name } = useParams();
  const [formState, setFormState] = React.useState({username: '', password: '', code: ''});
  const [errorState, setErrorState] = React.useState(false);
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = React.useState(false);
  const [passwordsMatchText, setPasswordsMatchText] = React.useState('');
  const [helperText, setHelperText] = React.useState("");
  const [HPUser, setHPUser] = React.useState(null);
  const [cannotCreateAccount, setCannotCreateAccount] = React.useState(false);
  const [needsCode, setNeedsCode] = React.useState(false);
  const [codeInvalid, setCodeInvalid] = React.useState(false);
  const [codeHelperText, setCodeHelperText] = React.useState('');

	 const formContainerStyle = {
    maxWidth: "600px",
    padding: "100px",
    margin: "0 auto",
    border: "10px solid grey",
  };

  

  React.useEffect(() => {
    const nameToHPUserMap = {
    'andrew' : 'be6f27cd-eb30-4e54-b6f0-0f7003e189ce',
    'sam'    : '396be5ef-926c-4d85-a30f-cf9acc076cdf',
    'ellen'  : '0193669d-ff9b-4112-9adf-2bb0718a6e97',
    'marcy'  : '00d657c5-93e5-4a7b-8640-07c4483f09d6',
    'lawson' : '18c4d39d-56ac-4e8a-9489-671cb69d590e',
    'david'  : 'e90a35dc-72de-4bc9-aa1d-f485861f3161',
    'susan'  : '7a44419d-6c15-49c9-b4e4-8e44855a543e',
    'marissa': '9f9f9e92-0c54-4ae9-90fb-9d16e4876705'
  };
    const getUser = async () => {
      const userId = nameToHPUserMap[name]
      const user = await DataStore.query(HusbandPointsUser, userId);
      setHPUser(user);
    };
    getUser();
  },[name])
  const updateFormState = ({currentTarget}) => {
    setCannotCreateAccount(false);
    const newFormState = {...formState, [currentTarget.name]: currentTarget.value};
    setFormState(newFormState);
  }

  const confirmPassword = ({currentTarget}) => {
    if(currentTarget.value === formState.password){
      setCannotCreateAccount(true);
      setPasswordsDoNotMatch(false);
      setPasswordsMatchText('');
    } else{
      setCannotCreateAccount(false);
      setPasswordsDoNotMatch(true);
      setPasswordsMatchText('Passwords do not match');
    }
  }

  const loginAttempt = async() => {
    try{
      const {username, password} = formState;
      const cognitoUser = await Auth.signUp({
              username, 
              password, 
              attributes: {
               name : name
             }
          });
      console.log(cognitoUser);
      const userId = cognitoUser.userSub;
      await DataStore.save(HusbandPointsUser.copyOf(HPUser, user => {
        user.userId = userId
      }));
      setNeedsCode(true);
    } catch(e){
      console.error(e);
      setErrorState(true);
      setHelperText(e.message);
    }
  }

  const confirmAttempt = async() => {
    try{
      const {username, password, code} = formState;
      await Auth.confirmSignUp(username, code);
      const cognitoUser = Auth.signIn(username, password);

      console.log(cognitoUser)
    } catch(e){
      console.error(e);
      setCodeInvalid(true);
      setCodeHelperText(e.message);

    }
  }


  return (
    <div style={formContainerStyle}>
      <Typography variant="h3">
        Welcome {HPUser ? HPUser.isHusband ? HPUser.Husband.name : HPUser.Approver.name : '...'}
      </Typography>
      <TextField
        autoFocus
        name="username"
        label="Email"
        error={errorState}
        helperText={helperText}
        fullWidth
        onChange={updateFormState}
        disabled={needsCode}
      />
      <TextField
        name="password"
        label="Password"
        type="password"
        error={errorState}
        helperText={helperText}
        fullWidth
        onChange={updateFormState}
        disabled={needsCode}
      />
      <TextField
        name="password_confirm"
        label="Confirm Password"
        type="password"
        error={passwordsDoNotMatch}
        helperText={passwordsMatchText}
        fullWidth
        onBlur={confirmPassword}
        disabled={needsCode}
      />
      <Button 
        onClick={loginAttempt} 
        disabled={!cannotCreateAccount || needsCode}
      >
        Create Account
      </Button>
      { needsCode && <>
          <br/>
          A confirmation code has been sent to {formState.username}
          <TextField
            label="Confirmation Code"
            name="code"
            fullWidth
            onChange={updateFormState}
            error={codeInvalid}
            helperText={codeHelperText}
          />
          <Button 
            onClick={confirmAttempt} 
          >Confirm </Button>
        </>

      }
      
    </div>
  );
}
