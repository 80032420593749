import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../routes/Login";
import Join from '../routes/join';

export default function LoggedOut() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/resetpassword/:token"></Route>
        <Route path="/join/:name">
          <Join />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Router>
  );
}
