// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ApprovalStatus = {
  "BLANK": "BLANK",
  "APPROVED": "APPROVED",
  "REJECTED": "REJECTED"
};

const EventStatus = {
  "DRAFT": "DRAFT",
  "PENDING": "PENDING",
  "APPROVED": "APPROVED",
  "DENIED": "DENIED"
};

const { HusbandPointsUser, Husband, Approver, Approval, Event } = initSchema(schema);

export {
  HusbandPointsUser,
  Husband,
  Approver,
  Approval,
  Event,
  ApprovalStatus,
  EventStatus
};