import { DataStore, SortDirection } from '@aws-amplify/datastore';
import { Event, EventStatus } from '../models';

export default async function getEvents(){
	const pendingEvents = await DataStore.query(Event,
		event => event
			.status('eq',EventStatus.PENDING),
		{
			sort: event => event.createdAt(SortDirection.DESCENDING),
			limit: 20,
			page: 0
		});
	const completedEvents = await DataStore.query(Event,
		event => event
			.status('ne',EventStatus.PENDING),
		{
			sort: event => event.createdAt(SortDirection.DESCENDING),
			limit: 20,
			page: 0
		});
	return [...pendingEvents, ...completedEvents];
}
